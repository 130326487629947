/* purgecss start ignore */

/* Add styles to text-content elements from Optimizely "Rich text editor" with exception of elements in blocks inside the "Rich text editor" */

.text-content {
    @apply mb-90 text-base;

    .component {
        .container {
            padding: 0;
        }
    }

    .component:not(.accordion):not(.iframe):not(.process):not(.highlight):not(.quote):not(.quote--no-quotemarks):not(.image-gallery-container) {
        .container {

            @media (min-width: theme('screens.md')) {
                width: 100vw;
                position: relative;
                left: calc(-1 * (100vw - 100%) / 2);
            }

            @media (min-width: theme('screens.xl')) {
                width: theme('screens.xl');
                position: relative;
                left: calc(-1 * (theme('screens.xl') - 100%) / 2);
            }
        }
    }

    .component.quote {
        figure {
            justify-content: flex-start;

            > div {
                width: 100%;
            }
        }
    }

    .component.quote--no-quotemarks {
        figure {
            justify-content: flex-start;

            > div {
                width: 100%;
            }
        }
    }

    img {
        @apply rounded-xl mt-60;
    }

    figure.image {
        figcaption {
            @apply font-source-sans-pro text-md mt-30 mb-60;
        }
    }
}

.text-content h1:not(.text-content > .component h1),
.text-content .text-content h1,
.FormParagraphText h1,
.h1 {
    @apply font-merriweather font-bold mb-30 text-h1-mobile md:text-h1;
}

.text-content h2:not(.text-content > .component h2),
.text-content .text-content h2,
.FormParagraphText h2,
.h2 {
    @apply font-merriweather font-bold mb-18 mt-50 text-h2-mobile md:text-h2;
}

.text-content h3:not(.text-content > .component h3),
.text-content .text-content h3,
.FormParagraphText h3,
.h3 {
    @apply font-merriweather mb-18 mt-50 text-h3;
}

.text-content h4:not(.text-content > .component h4),
.text-content .text-content h4,
.FormParagraphText h4,
.h4 {
    @apply font-merriweather mb-18 mt-50 text-h4;
}

.text-content a:not(.text-content > .component a),
.text-content .text-content a,
.FormParagraphText a,
.link {
    @apply break-words text-orionBlue underline;

    &:hover,
    &focus-visible {
        @apply text-black;
    }
}

.text-content p:not(.text-content > .component p),
.text-content .text-content p,
.FormParagraphText p {
    @apply my-18;
}

.text-content ul {
    @apply leading-relaxed list-disc ml-15 my-30 pl-10;
}

.text-content ol {
    @apply leading-relaxed list-decimal ml-15 my-30 pl-10;
}

.text-content img:not(.text-content > .component img) {
    /*@apply rounded-xl mb-80 mt-30;*/
    /*max-width: none;*/
    /*margin-left: calc(-1.5rem);*/
    /*margin-right: calc(-1.5rem);*/
    /*width: calc(100% + 3rem);*/

    /*max-width: 100%;*/

    @media (min-width: theme('screens.xl')) {
        /*margin-left: calc(-10rem);*/
        /*margin-right: calc(-10rem);*/
        /*width: calc(100% + 20rem);*/
    }
}

.text-content {
    table {
        @apply border-collapse w-full;

        td:not(td:first-child) {
            @apply text-right;
        }

        &.table--left-center {
            td:first-child {
                @apply text-left;
            }
            td:not(td:first-child) {
                @apply text-center;
            }
        }

        &.table--left {
            td:not(td:first-child) {
                @apply text-left;
            }
        }
    }

    th, td {
        @apply align-top p-15;
        border-bottom: 1px solid theme('colors.gray.300');
        height: auto !important;
        min-width: 10rem;
        width: auto !important;

        &:first-child {
            @apply pl-0;
        }

        &:last-child {
            @apply pr-0;
        }
    }

    thead td, th {
        @apply font-eina-semibold text-left;
    }
}

/* arrow link for text-content */
        .text-content {
            .arrow-link {
                @apply no-underline !important;

                &::after {
                    @apply ml-5 material-icons transition-transform translate-x-0;
                    content: "\E8E4";
                    font-size: 32px;
                }

                &:hover {
                    &::after {
                        @apply translate-x-4;
                    }
                }
            }
        }
        /* text-content modifier for components */
        .text-content--component {
            @apply my-0;

            > *:first-child {
                @apply mt-0 !important;
            }

            > *:last-child {
                @apply mb-0 !important;
            }
        }
        /* text-content modifier for process block */
        .text-content--process {
            h3,
            h4,
            h5 {
                + ul,
                + p {
                    @apply -mt-20 !important;
                }
            }
        }
        /* text-content modifier for release pages */
        .text-content--release {
            img {
                @apply m-0 w-auto !important;
            }
        }
        /* purgecss end ignore */