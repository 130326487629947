/* purgecss start ignore */
/* add smaller margin top to the first component inside this class */
.components {
    div:first-child {
        .component {
            @apply mt-45; 
        }
    }
}

/* Custom select arrow */
.custom-select {
    background-color: theme('colors.white');
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 11L0.937823 0.499999L13.0622 0.5L7 11Z' fill='%23010C1D'/%3E%3C/svg%3E%0A");
    background-position: center right 15px;
    background-repeat: no-repeat;
}

/* Remove highlight color on swiper buttons */
.swiper-button-next span::selection,
.swiper-button-prev span::selection {
    background: transparent;
}
/* purgecss end ignore */