/* purgecss start ignore */
.footer {
	p {
		color: #000;
	}
}

.appearance-none {
	/* Chrome, Safari, Edge, Opera */
	&input::-webkit-outer-spin-button,
	&input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	&input[type=number] {
		-moz-appearance: textfield;
	}
}
/* purgecss end ignore */