/* purgecss start ignore */
@media (min-width: 768px) {
	.heroArticleItem {
		&:before {
			position: absolute;
			content: '';
			height: 6px;
			top: 0;
			margin: 0 auto;
			left: 0;
			right: 0;
			width: 0%;
			background: #0073be;
			transition: .25s;
		}

		&.active {
			&:before {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 1024px) and (max-height: 960px) {
	.aspect-hero {
		aspect-ratio: 27/9 !important;

		&.object-cover {
			object-position: 50% 40%;
		}
	}
}

.autoplay-progress__indicator {
	--progress: 0;
	position: absolute;
	left: 0;
	top: 0px;
	z-index: 10;
	width: 100%;
	height: 100%;
	stroke-width: 2px;
	stroke: currentColor;
	fill: none;
	stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
	stroke-dasharray: 125.6;
	transform: rotate(-90deg);
}
/* purgecss end ignore */