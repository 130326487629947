.infobox {
	ul {
		//margin-top: 3rem;
		//margin-bottom: 3rem;
		margin-left: 1.5rem;
		list-style-type: disc;
		padding-left: 1rem;
		line-height: 1.625;
	}
}
