/* Used in Optimizely dropdown and icon-links-block */
.icon {
    display: inline-block;
    height: 24px;
    width: 24px;

    &::after {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
    }
}

/* Used in Optimizely to have blue bg when white icons */
.dijitReset {
    .icon {
        height: 24px !important;
        padding: 5px;
        width: 24px !important;
    }

    .icon-orion-icon-newsroom,
    .icon-orion-icon-service-desk,
    .icon-orion-icon-career,
    .icon-orion-icon-sustainability-white,
    .icon-orion-icon-newsletter-white,
    .icon-orion-icon-pharma-white {
        background-color: #00509B;
    }
}

/* icon links */
.icon-orion-icon-science-and-partnering::after {
    background-image: url(/Static/icons/sience-and-partnering.svg);
}

.icon-orion-icon-sustainability::after {
    background-image: url(/Static/icons/sustainability.svg);
}

.icon-orion-icon-we-are-orion::after {
    background-image: url(/Static/icons/we-are-orion.svg);
}

.icon-orion-icon-orion-and-society::after {
    background-image: url(/Static/icons/orion-and-society.svg);
}

.icon-orion-icon-your-health::after {
    background-image: url(/Static/icons/your-health.svg);
}

/* search quicklinks */
.icon-orion-icon-newsroom::after {
    background-image: url(/Static/icons/newsroom.svg);
}
.icon-orion-icon-service-desk::after {
    background-image: url(/Static/icons/service-desk.svg);
}
.icon-orion-icon-career::after {
    background-image: url(/Static/icons/career.svg);
}
.icon-orion-icon-sustainability-white::after {
    background-image: url(/Static/icons/sustainability-white.svg);
}
.icon-orion-icon-pharma-white::after {
    background-image: url(/Static/icons/pharma-white.svg);
}
.icon-orion-icon-newsletter-white::after {
    background-image: url(/Static/icons/newsletter-white.svg);
}



.Sleek .epi-resourceInputContainer .epi-resourceName {
    background-color: #0073d9 !important;
    color: #fff !important;
}