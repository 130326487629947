.adSwiper-pagination {
	@apply mb-30 mt-20 flex justify-center;
	gap: 20px;

	.swiper-pagination-bullet {
		@apply inline-block w-16 h-16 rounded-full bg-ice;
	}

	.swiper-pagination-bullet-active {
		@apply bg-aesculapius;
	}
}
