@import 'typography.scss';
@import 'global-elements.scss';
@import 'navigation.scss';
@import 'language-menu.scss';
@import 'buttons.scss';
@import 'text-content.scss';
@import 'page-header-search.scss';
@import 'site-search.scss';
@import 'edit.scss';
@import 'modal.scss';

@import 'forms/form-elements.scss';

@import 'components/accordion.scss';
@import 'components/adBlock.scss';
@import 'components/heroCarousel.scss';
@import 'components/infobox.scss';
@import 'components/quote.scss';
@import 'components/tabs.scss';
@import 'components/process.scss';
@import 'components/askem.scss';

@import 'material-content.scss';

@import 'utilities.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
body {
	@apply m-0 p-0;
}

#epi-quickNavigator {
	opacity: .25;
	transition: all .5s;
	transform: translateY(-75%);

	&:hover {
		opacity: 1;
		transform: translateY(0%);
	}
}

#epi-quickNavigator-logo {
	display: none;
}

/* purgecss end ignore */

