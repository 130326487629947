
.quote__image {
    img {
        margin-top: 0;
    }
}
/*

.quote__image {
    &::after {
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 100%;
        position: absolute;
        transition: transform 0.3s;
        width: 100%;
        z-index: -1;

        @media screen and (min-width: theme('screens.md')) {
            height: 140%;
        }
    }
}

.quote-left {
    .quote__image {
        &::after {
            background-image: url(/Static/images/quote-blob.png);
            left: -50%;
            top: 55%;
            transform: rotate(40deg) translateY(-60%);

            @media screen and (min-width: theme('screens.md')) {
                left: -49%;
            }
        }
    }
    .container:hover {
        .quote__image {
            &::after {
                transform: rotate(40deg) translateY(calc(-60% - 1rem)) translateX(1rem) scale(0.95);
            }
        }
    }
}
        */
.quote-right {
    .quote__image {
        justify-content: flex-start;
        /*
        &::after {
            background-image: url(/Static/images/quote-blob-right.png);
            right: -64%;
            top: 41%;
            transform: rotate(-40deg) translateY(-60%);

            @media screen and (min-width: theme('screens.md')) {
                right: -66%;
                top: 25%;
            }
        }*/
    }
    /*
    .container:hover {
        .quote__image {
            &::after {
                transform: rotate(-40deg) translateY(calc(-60%)) translateX(1rem) scale(0.95);
            }
        }
    }
        */
}
