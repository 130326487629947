@import './../../Static/fontawesome/all.min.css';

/* Fonts from https://gwfh.mranftl.com/fonts/ */

/* purgecss start ignore */
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	src: url('/Static/webfonts/merriweather-v30-latin-regular.eot'); /* IE9 Compat Modes */
	src: url('/Static/webfonts/merriweather-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/Static/webfonts/merriweather-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/Static/webfonts/merriweather-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/Static/webfonts/merriweather-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/Static/webfonts/merriweather-v30-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 700;
	src: url('/Static/webfonts/merriweather-v30-latin-700.eot'); /* IE9 Compat Modes */
	src: url('/Static/webfonts/merriweather-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/Static/webfonts/merriweather-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('/Static/webfonts/merriweather-v30-latin-700.woff') format('woff'), /* Modern Browsers */
	url('/Static/webfonts/merriweather-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/Static/webfonts/merriweather-v30-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	src: url('/Static/webfonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
	src: url('/Static/webfonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/Static/webfonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('/Static/webfonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
	url('/Static/webfonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/Static/webfonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: url('/Static/webfonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
	src: url('/Static/webfonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/Static/webfonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/Static/webfonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/Static/webfonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/Static/webfonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: url('/Static/webfonts/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
	src: url('/Static/webfonts/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/Static/webfonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('/Static/webfonts/source-sans-pro-v21-latin-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
	url('/Static/webfonts/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
	url('/Static/webfonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

html {
	font-size: 62.5%;
}

body {
	@apply font-merriweather text-base text-primary;
	-webkit-font-smoothing: antialiased;
}

/*
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('/Static/webfonts/MaterialIcons-Regular.eot');
	src: local('Material Icons'), local('MaterialIcons-Regular'),
	url('/Static/webfonts/MaterialIcons-Regular.woff2') format('woff2'),
	url('/Static/webfonts/MaterialIcons-Regular.woff') format('woff'),
	url('/Static/webfonts/MaterialIcons-Regular.ttf') format('truetype');
}
	*/

.material-icons {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}
/* purgecss end ignore */