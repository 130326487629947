/*
  Askem CSS Style Guide
*/
/* General styles */
.askem {
	display: flex;
	justify-content: center;
	.askem-plugin {
		/* Add reaction button label text styles here. */
		text-align: center;
	}

	.askem-plugin .askem-header {
		/* This affects all headers in the plugin */
	}

	.askem-plugin .askem-reactions-group {
		/* This affects the reaction buttons */
		justify-content: center;
	}
	/* Reaction button */
	.askem-plugin .askem-reactions-group .askem-reaction-button {
		/* Add reaction button styles here. */
	}

	/* Label */
	.askem-plugin .askem-reactions-group .askem-reaction-button .askem-label {
		/* Add reaction button label text styles here. */
	}

	/* For hover/active styles, just add :hover after .askem-reaction-button */
	/* For selected button styles, add .selected to .askem-reaction (e.g. .askem-plugin .askem-reactions-group .askem-reaction.selected .askem-reaction-button) */

	/* Feedback form */
	/* Textarea */
	.askem-plugin .askem-inputs .askem-input-field {
		/* Add textarea styles here. */
	}

	/* Disclaimer text */
	.askem-plugin .askem-inputs .askem-input-disclaimer {
		/* Add disclaimer text styles here */
	}

	/* Submit button */
	.askem-plugin .askem-inputs .askem-input-submit {
		/* Add feedback form submit button styles here. */
	}
}