/* purgecss start ignore */

.process .text-content {
    h1 {
        @apply font-eina-semibold mb-30 text-h1-mobile md:text-h1;
    }

    h2 {
        @apply font-eina-semibold mb-30 mt-50 text-h2-mobile md:text-h2;
    }

    h3 {
        @apply font-eina-semibold mb-30 mt-40 text-h3;
    }

    h4 {
        @apply font-eina-semibold mb-30 mt-30 text-h4;
    }
}

.process-list__item-content::after {
    height: 0;
    transition: height 500ms linear;
}

.process-list__item:nth-child(2) .process-list__item-content::after {
    transition-delay: 500ms;
}
.process-list__item:nth-child(3) .process-list__item-content::after {
    transition-delay: 1000ms;
}
.process-list__item:nth-child(4) .process-list__item-content::after {
    transition-delay: 1500ms;
}
.process-list__item:nth-child(5) .process-list__item-content::after {
    transition-delay: 2000ms;
}
.process-list__item:nth-child(6) .process-list__item-content::after {
    transition-delay: 2500ms;
}
.process-list__item:nth-child(7) .process-list__item-content::after {
    transition-delay: 3000ms;
}
.process-list__item:nth-child(8) .process-list__item-content::after {
    transition-delay: 3500ms;
}
.process-list__item:nth-child(9) .process-list__item-content::after {
    transition-delay: 4000ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 4500ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 5000ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 5500ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 6000ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 6500ms;
}
.process-list__item:nth-child(10) .process-list__item-content::after {
    transition-delay: 7000ms;
}

.process.in-viewport .process-list__item-content::after {
    height: 100%;
}

.process-list__item-content * {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
    transition: opacity 1s, transform 0.7s;
    transition-delay: 500ms;
    will-change: opacity, transform;
}

.process.in-viewport .process-list__item-content * {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.process-list__item:nth-child(2) .process-list__item-content * {
    transition-delay: 1000ms;
}

.process-list__item:nth-child(3) .process-list__item-content * {
    transition-delay: 1500ms;
}

.process-list__item:nth-child(4) .process-list__item-content * {
    transition-delay: 2000ms;
}

.process-list__item:nth-child(5) .process-list__item-content * {
    transition-delay: 2500ms;
}

.process-list__item:nth-child(6) .process-list__item-content * {
    transition-delay: 3000ms;
}

.process-list__item:nth-child(7) .process-list__item-content * {
    transition-delay: 3500ms;
}

.process-list__item:nth-child(8) .process-list__item-content * {
    transition-delay: 4000ms;
}

.process-list__item:nth-child(9) .process-list__item-content * {
    transition-delay: 4500ms;
}

.process-list__item:nth-child(10) .process-list__item-content * {
    transition-delay: 5000ms;
}

.process-list__item:nth-child(11) .process-list__item-content * {
    transition-delay: 5500ms;
}

.process-list__item:nth-child(12) .process-list__item-content * {
    transition-delay: 6000ms;
}

.process-list__item:nth-child(13) .process-list__item-content * {
    transition-delay: 6500ms;
}

.process-list__item:nth-child(14) .process-list__item-content * {
    transition-delay: 7000ms;
}

.process-list__item:nth-child(15) .process-list__item-content * {
    transition-delay: 7500ms;
}

/* purgecss end ignore */
