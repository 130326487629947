/* purgecss start ignore */

button, a {
    /* base button style */
    &.button {
        @apply font-source-sans-pro inline-block text-md px-20 py-15 rounded-full text-center transition-all;
        will-change: transform;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            transform: translate3d(0, -2px, 0);
        }

        &:focus-visible {
            @apply bg-orionDarkBlue shadow-none text-white;
        }

        &.translate3d-0 {
            transform: translate3d(0, 0, 0);
        }

        &.button-small {
            @apply px-20 py-10 text-md;
        }
    }
    /* AESCU default light blue */
    &.button--default-light-blue {
        @apply bg-aesculapius10;

        &:hover {
            @apply bg-aesculapius text-white;
        }
    }
    /* AESCU default dark blue */
    &.button--default-dark-blue {
        @apply bg-darkBlue text-white;

        &:hover {
            @apply bg-aesculapius text-white;
        }
    }
    /* default blue */
    &.button--default-blue {
        @apply bg-orionBlue text-white;

        &:hover {
            @apply bg-orionDarkBlue;
        }
    }
    /* default white */
    &.button--default-white {
        @apply bg-white text-black;

        &:hover {
            @apply bg-orionDarkBlue text-white;
        }
    }
    /* outline blue */
    &.button--outline-blue {
        @apply bg-white text-orionBlue;
        box-shadow: inset 0 0 0 2px theme('colors.orionBlue');

        &:hover {
            @apply text-orionMiddleBlue;
            box-shadow: inset 0 0 0 2px theme('colors.orionBlue'),rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        }

        &.lock-hover {
            @apply text-orionMiddleBlue;
            box-shadow: inset 0 0 0 2px theme('colors.orionBlue'),rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        }
    }
    /* outline black */
    &.button--outline-black {
        @apply bg-white text-black;
        box-shadow: inset 0 0 0 2px #000;

        &:hover {
            box-shadow: inset 0 0 0 2px #000,rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        }
    }
}

/* arrow link */
.arrow-link {
    @apply inline-flex font-source-sans-pro items-center text-aesculapius font-semibold;

    .material-icons {
        @apply ml-5 relative text-[150%] top-[2px] transition-transform;
    }

    &:hover {
        @apply text-darkBlue;

        .material-icons {
            @apply translate-x-5;
        }
    }
}

.play {
    &:after {
        content: "";
        display: block;
        height: 7rem;
        width: 7rem;
        background-size: 7rem 7rem;
        background-image: url(/Static/img/icon-play.svg);
        background-repeat: no-repeat;
        transition: .1s ease-in-out;
    }

    &:hover {
        &:after {
            height: 9rem;
            width: 9rem;
            background-size: 9rem 9rem;
            margin-top: -1rem;
        }
    }
}

.stop {
    &:after {
        content: "";
        width: 5rem;
        height: 5rem;
        content: "";
        display: block;
        height: 7rem;
        width: 7rem;
        background-size: 7rem 7rem;
        margin: 1rem;
        background-image: url(/Static/img/icon-pause.svg);
        background-repeat: no-repeat;
        transition: .1s ease-in-out;
    }

    &:hover {
        &:after {
            height: 9rem;
            width: 9rem;
            background-size: 9rem 9rem;
            margin: 0;
        }
    }
}
/* purgecss end ignore */