.page-header__search-toggler {
    &.is-active {
        span {
            @apply underline;
        }

        .fa-search {
            &::before {
                @apply mx-2;
                content: "\f00d";
            }
        }
    }
}

.page-header__search-form {

    &.is-active {
        @apply opacity-100 translate-y-0 visible;
        form {
            @apply block;
        }
    }
}
