/* purgecss start ignore */

/* Add styles to text-content elements from Optimizely "Rich text editor" with exception of elements in blocks inside the "Rich text editor" */
.digitalMaterialPage {
	.material-column {
		@media (min-width: theme('screens.xl')) {
			flex-basis: calc(50% - .9rem);
			max-width: calc(50% - .9rem);
		}
	}
}

.material-content {
	> div {
		@apply p-18 bg-white pb-10;

		&:last-child {
			@apply mb-0;
		}
	}

	div {
		& > p {
			@apply mt-0;
		}

		& > h2 {
			@apply mt-0;
		}
	}

	> div:not(:last-child) {
		@apply mb-18;
	}

	@apply text-sm;

	.component {
		.container {
			padding: 0;
		}
	}

	.component:not(.accordion):not(.iframe):not(.process):not(.highlight):not(.quote) {
		.container {

			@media (min-width: theme('screens.md')) {
				width: 100vw;
				position: relative;
				left: calc(-1 * (100vw - 100%) / 2);
			}

			@media (min-width: theme('screens.xl')) {
				width: theme('screens.xl');
				position: relative;
				left: calc(-1 * (theme('screens.xl') - 100%) / 2);
			}
		}
	}

	.component.quote {
		.container {
			@media (min-width: theme('screens.xl')) {
				width: theme('screens.xl');
				position: relative;
				left: calc(-1 * (theme('screens.xl') - 100%) / 2);
			}
		}
	}

	p:has(img) {
		@apply my-0 inline-block;
		max-width: 49.5%;
	}

	ul > li {
		@apply my-0;
	}

	h2, h3, h4 {
		& + ul {
			@apply mt-0;
		}
	}

	figure.image {
		figcaption {
			@apply font-source-sans-pro text-md mt-30 mb-60;
		}
	}
}

.material-content {
	h1 {
		@apply font-source-sans-pro font-bold mb-30 text-xxl;
	}

	h2 {
		@apply font-source-sans-pro font-bold mb-18 mt-18 text-xl;
	}

	h3 {
		@apply font-source-sans-pro mb-18 mt-18 text-lg;
	}

	h4 {
		@apply font-source-sans-pro mb-18 mt-18 text-lg;
	}
}

.digitalMaterialPage {
	&.materialColorVariantTeal {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryTeal;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryTeal;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryTeal;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialAccentTeal;
					}
				}
			}
		}
	}

	&.materialColorVariantBlue {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryBlue;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryBlue;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryBlue;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialLightBlue;
					}
				}
			}
		}
	}

	&.materialColorVariantGreen {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryGreen;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryGreen;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryGreen;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialAccentGreen;
					}
				}
			}
		}
	}

	&.materialColorVariantOrange {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryOrange;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryOrange;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryOrange;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialAccentOrange;
					}
				}
			}
		}
	}

	&.materialColorVariantPurple {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryPurple;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryPurple;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryPurple;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialLightPurple;
					}
				}
			}
		}
	}

	&.materialColorVariantMustardGreen {
		.material-content {
			h1, h2, h3, h4 {
				@apply text-materialPrimaryMustardGreen;
			}

			table {
				&.table--material-color-first-col {
					td:first-child {
						@apply text-materialPrimaryMustardGreen;
					}
				}

				&.table--material-color-first-row {
					tr:first-child {
						@apply text-materialPrimaryMustardGreen;
					}
				}

				&.table--material-color-bg {
					td:first-child {
						@apply bg-materialAccentMustardGreen;
					}
				}
			}
		}
	}
}

.material-content {
	table {
		@apply border-collapse w-full text-xs;

		&.table--material-color-first-col {
			td:first-child {
				@apply text-left;
			}
		}

		&.table--material-color-first-row {
			tr:first-child {
				@apply text-left;
			}
		}

		&.table--material-color-bg {
			td:first-child {
				@apply text-left pl-6;
			}
		}
		
		p {
			@apply m-0;
		}
		h1, h2, h3, h4 {
			@apply m-0;
		}
	}

	th, td {
		@apply align-top p-10;
		border-bottom: 1px solid theme('colors.gray.300');
		height: auto !important;
		min-width: 7rem;
		width: auto !important;

		&:first-child {
			@apply pl-0;
		}

		&:last-child {
			@apply pr-0;
		}
	}

	thead td, th {
		@apply font-eina-semibold text-left;
	}

	strong {
		> em {
			@apply not-italic text-alertRed;
		}
	}
}


.material-content a {
	@apply text-orionBlue underline;

	&:hover,
	&focus-visible {
		@apply text-black;
	}
}

.material-content p {
	@apply my-18;
}

.material-content ul {
	@apply leading-relaxed list-disc ml-15 mt-0 mb-10 pl-10;

	ul {
		@apply mb-0;
	}
}

.material-content ol {
	@apply leading-relaxed list-decimal ml-15 mt-0 mb-10 pl-10;

	ol {
		@apply mb-0;
	}
}

.material-content .component img {
	/*@apply rounded-xl mb-80 mt-30;*/
	/*max-width: none;*/
	/*margin-left: calc(-1.5rem);*/
	/*margin-right: calc(-1.5rem);*/
	/*width: calc(100% + 3rem);*/
	/*max-width: 100%;*/

	@media (min-width: theme('screens.xl')) {
		/*margin-left: calc(-10rem);*/
		/*margin-right: calc(-10rem);*/
		/*width: calc(100% + 20rem);*/
	}
}


/* arrow link for text-content */
.material-content {
	.arrow-link {
		@apply no-underline !important;

		&::after {
			@apply ml-5 material-icons transition-transform translate-x-0;
			content: "\E8E4";
			font-size: 32px;
		}

		&:hover {
			&::after {
				@apply translate-x-4;
			}
		}
	}
}
/* text-content modifier for components */
.material-content--component {
	@apply my-0;

	> *:first-child {
		@apply mt-0 !important;
	}

	> *:last-child {
		@apply mb-0 !important;
	}
}
/* text-content modifier for process block */
.material-content--process {
	h3,
	h4,
	h5 {
		+ ul,
		+ p {
			@apply -mt-20 !important;
		}
	}
}
/* text-content modifier for release pages */
.material-content--release {
	img {
		@apply m-0 w-auto !important;
	}
}
/* purgecss end ignore */
