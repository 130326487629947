/* purgecss start ignore */

.icon-language {
    &:after {
        font-family: 'Material Icons';
        content: "\e894";
        vertical-align: middle;
        font-size: 2.6rem;

        @media (min-width: theme('screens.lg')) {
            font-size: 1.8rem;
        }
    }
}

.language-menu-toggler {
    &.is-active {
        @apply text-orionBlue;
    }
}

.language-menu {
    &.is-active {
        @apply opacity-100 translate-y-0 visible;

        &::after {
            @media (min-width: theme('screens.lg')) {
                @apply h-screen fixed w-full;
                background: rgba(0,35,74, 0.8);
                content: "";
            }
        }
    }
}


:root {
    --grid-col: calc(16/2);
}

.language-menu__countries {
    @media (min-width: theme('screens.sm')) {
        --grid-col: var(--grid-col-2) !important;
        grid-auto-flow: column;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(var(--grid-col),1fr);
    }

    @media (min-width: theme('screens.lg')) {
        --grid-col: var(--grid-col-3) !important;
        grid-template-columns: repeat(3,1fr);
    }
}

﻿.language-menu__country:focus-within {
    .language-menu__languages {
        @apply block;
    }
}

/* purgecss end ignore */